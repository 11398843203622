import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// prettier-ignore
const ProductType = () => import('@/entities/product-type/product-type.vue');
// prettier-ignore
const ProductTypeUpdate = () => import('@/entities/product-type/product-type-update.vue');
// prettier-ignore
const ProductTypeDetails = () => import('@/entities/product-type/product-type-details.vue');
// prettier-ignore
const Product = () => import('@/entities/product/product.vue');
// prettier-ignore
const ProductUpdate = () => import('@/entities/product/product-update.vue');
// prettier-ignore
const ProductDetails = () => import('@/entities/product/product-details.vue');
// prettier-ignore
const Organization = () => import('@/entities/organization/organization.vue');
// prettier-ignore
const OrganizationUpdate = () => import('@/entities/organization/organization-update.vue');
// prettier-ignore
const OrganizationDetails = () => import('@/entities/organization/organization-details.vue');
// prettier-ignore
const Area = () => import('@/entities/area/area.vue');
// prettier-ignore
const AreaUpdate = () => import('@/entities/area/area-update.vue');
// prettier-ignore
const AreaDetails = () => import('@/entities/area/area-details.vue');
// prettier-ignore
const Order = () => import('@/entities/order/order.vue');
// prettier-ignore
const OrderUpdate = () => import('@/entities/order/order-update.vue');
// prettier-ignore
const OrderDetails = () => import('@/entities/order/order-details.vue');
// prettier-ignore
const Reservation = () => import('@/entities/reservation/reservation.vue');
// prettier-ignore
const ReservationUpdate = () => import('@/entities/reservation/reservation-update.vue');
// prettier-ignore
const ReservationDetails = () => import('@/entities/reservation/reservation-details.vue');
// prettier-ignore
const Invoice = () => import('@/entities/invoice/invoice.vue');
// prettier-ignore
const InvoiceUpdate = () => import('@/entities/invoice/invoice-update.vue');
// prettier-ignore
const InvoiceDetails = () => import('@/entities/invoice/invoice-details.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  meta: { authorities: [Authority.ADMIN] },
  children: [
    {
      path: 'product-type',
      name: 'ProductType',
      component: ProductType,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'product-type/new',
      name: 'ProductTypeCreate',
      component: ProductTypeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'product-type/:productTypeId/edit',
      name: 'ProductTypeEdit',
      component: ProductTypeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'product-type/:productTypeId/view',
      name: 'ProductTypeView',
      component: ProductTypeDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'product',
      name: 'Product',
      component: Product,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'product/new',
      name: 'ProductCreate',
      component: ProductUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'product/:productId/edit',
      name: 'ProductEdit',
      component: ProductUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'product/:productId/view',
      name: 'ProductView',
      component: ProductDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'organization',
      name: 'Organization',
      component: Organization,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'organization/new',
      name: 'OrganizationCreate',
      component: OrganizationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'organization/:organizationId/edit',
      name: 'OrganizationEdit',
      component: OrganizationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'organization/:organizationId/view',
      name: 'OrganizationView',
      component: OrganizationDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'area',
      name: 'Area',
      component: Area,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'area/new',
      name: 'AreaCreate',
      component: AreaUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'area/:areaId/edit',
      name: 'AreaEdit',
      component: AreaUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'area/:areaId/view',
      name: 'AreaView',
      component: AreaDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'order',
      name: 'Order',
      component: Order,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'order/new',
      name: 'OrderCreate',
      component: OrderUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'order/:orderId/edit',
      name: 'OrderEdit',
      component: OrderUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'order/:orderId/view',
      name: 'OrderView',
      component: OrderDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'reservation',
      name: 'Reservation',
      component: Reservation,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'reservation/new',
      name: 'ReservationCreate',
      component: ReservationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'reservation/:reservationId/edit',
      name: 'ReservationEdit',
      component: ReservationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'reservation/:reservationId/view',
      name: 'ReservationView',
      component: ReservationDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'invoice',
      name: 'Invoice',
      component: Invoice,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'invoice/new',
      name: 'InvoiceCreate',
      component: InvoiceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'invoice/:invoiceId/edit',
      name: 'InvoiceEdit',
      component: InvoiceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'invoice/:invoiceId/view',
      name: 'InvoiceView',
      component: InvoiceDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
